.ImageSection{
display: flex;
margin-top: 4rem;
margin-bottom: 4rem;
.img{
    width: 100%;
    height: 50vh;
    img{
        width: 15rem;
        object-fit: cover;
        height: 100%;
    }
}

    .about-info{
        margin-left: 2rem;
        h4{
            font-size: 2rem;
        }
        .about-text{
            padding: 1rem 0;
        }
    }
    @media screen and (max-width: 1400px){
        flex-direction: column;
        justify-content: center;
        .about-info{
            margin-left: 0;
            margin-top: 1rem;
        }
    }

}
.about-details{
    display: flex;
    p{
        padding: 0.3rem 0;
    }

    .right-section{
        margin-left: 2.3rem;
    }
   
   
    
}
.about-details{
    display: flex;
    p{
        padding: .3rem 0;
    }
    .right-section{
        margin-left: 2.3rem;
    }
}



.btn{
    padding: 1rem 2rem;
    background-color: #037FFF;
    outline: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 2px;
    margin-top: 1rem;
    position: relative;
    &::after{
        position: absolute;
        content: "";
        width: 0;
        height: .3rem;
        left: 0;
        bottom: 0;
        background-color: #a4acc4;;
        transition: all 0.4s ease-in-out;
    }
    &:hover::after{
        width: 100%;
    }
}

.skillsContainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
    row-gap: 3rem;
    margin: 4rem 0;
    @media screen and (max-width: 1100px){
        grid-template-columns: repeat(1, 1fr);
    }
}

.btn-port{
    padding: .6rem 2rem;
    background-color: #0381ff5b;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    color: white;
    margin-bottom: 1rem;
    transition: all .3s ease-in-out;
    &:not(:last-child){
        margin-right: 1rem;
    }
    &:hover{
        background-color: #037FFF;
    }
    &:active{
        background-color: #037FFF;
    }
    &:focus{
        background-color: #037FFF;
    }
    

}

.buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 4rem;
    flex-wrap: wrap;
    @media screen and (max-width: 1270px){
        margin: 2rem 5rem;
    }
    @media screen and (max-width: 1100px){
        margin: 2rem 2rem;
    }
}