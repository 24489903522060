
.HomePage{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width:80%;

}

.hero-text{
color: white;
text-align: center;
font-size: 3.6rem;
    span{
        color: #037FFF;
    }

}
.h-sub-text{
    text-align: center;
}
.icons{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    .icon-container{
        margin-right: 1rem;
        .icon{
            color: #a4acc4;
            font-size: 2.8rem;
            transition: all .4s ease-in-out;
        }
        .gh:hover{
            color:white;
        }
        .sp:hover{
            color:#1db954;
        }
        .ln:hover{
            color:#0077B5;
        }
    }
}

