.ContactItem{
    width: 100%;
    margin: 1rem 0;
    .contact{
        display: flex;
        align-items: center;
        background-color: #191D2B;
        padding: 2rem 0;
        .right-items{
            margin-left: 2rem;
        }
        img{
            padding: 1rem;
            border: 1px solid #2e344e;
            margin-left: 2rem;
            width: 16%;
        }
        .right-items{
            font-size: 1.4rem;
            font-weight: 500;
        }
        a{
            text-decoration: none;
            color: inherit;
        }
    }
}
.ContactPage{
    display: flex;
    .contact-sect{
        width: 100%;

    }
}
.title{
    margin-bottom: 5rem;
}