@import './styles/layout';
@import './styles/navbar';
@import './styles/typography';
@import './styles/title';
@import './styles/about';
@import './styles/skill';
@import './styles/contact';
@import './styles/portfolios';

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body::-webkit-scrollbar{
    width: 8px;
}
body::-webkit-scrollbar-thumb{
    background-color: #0056d898;
    border-radius: 50px;
}
body::-webkit-scrollbar-track{
    background-color: #777;
}

body{
    color: #a4acc4;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 1.2rem;
}
span{
    color: #037FFF;
}

@media screen and (max-width: 411px){
    html{
        font-size: 70%;
    }
}
@media screen and (max-width: 700px){
    html{
        font-size: 90%;
    }
}
@media screen and (max-width: 480px){
    h2{
        font-size: 70%;
    }
    h3{
        font-size: 70%;
    }
}